import React, { Component } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/SEO"
import Layout from "../layouts/default"
import SelectField from "../components/CustomSelectField"
import BackButton from "../components/BackButton"
import QuoteForm from "../components/QuoteForm"
import formatProductTitle from "../helpers/formatProductTitle"
import { Modal } from "react-bootstrap"

var initialValuesPoundsNPerCubicYard = {
  low: {
    "2-3-months": 0.6,
    "3-4-months": 0.5,
    "5-6-months": 0.8,
    "8-9-months": 1.3,
    "12-14-months": 1.8,
    "14-16-months": 2.6,
  },
  medium: {
    "2-3-months": 1.0,
    "3-4-months": 1.2,
    "5-6-months": 1.5,
    "8-9-months": 2.0,
    "12-14-months": 2.5,
    "14-16-months": 3.3,
  },
  high: {
    "2-3-months": 1.3,
    "3-4-months": 1.785,
    "5-6-months": 2.2,
    "8-9-months": 2.7,
    "12-14-months": 3.2,
    "14-16-months": 4.0,
  },
}
var initialValuesPoundsNPerCubicYardLandscapeBed = {
  low: {
    "2-3-months": 1.0,
    "3-4-months": 1.0,
    "5-6-months": 2.0,
    "8-9-months": 2.0,
    "12-14-months": 2.0,
    "14-16-months": 2.0,
  },
  medium: {
    "2-3-months": 2.0,
    "3-4-months": 2.0,
    "5-6-months": 3.0,
    "8-9-months": 3.5,
    "12-14-months": 4.0,
    "14-16-months": 4.0,
  },
  high: {
    "2-3-months": 3.0,
    "3-4-months": 3.0,
    "5-6-months": 4.0,
    "8-9-months": 5.0,
    "12-14-months": 6.0,
    "14-16-months": 6.0,
  },
}
function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}
class ProductTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showQuoteForm: false,
      applicationEnv: "incorporation",
      longevity: "2-3-months",
      nValue: props.data.wp.product.productFields.nValue,
      incorporationRates: {
        poundsPerCubicYard: {
          low: "",
          medium: "",
          high: "",
        },
        kilogramsPerCubicMeter: {
          low: "",
          medium: "",
          high: "",
        },
        gramsPerLiter: {
          low: "",
          medium: "",
          high: "",
        },
      },
      landscapeBedRates: {
        poundsPerThousandSqFt: {
          low: "",
          medium: "",
          high: "",
        },
      },
      greenhouseContainerRates: [
        {
          label: '4" standard (0.88 pint)',
          containersPerCubicYard: 1836,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '4" square (1.25 pint)',
          containersPerCubicYard: 1293,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '5" azalea (1.62 pints)',
          containersPerCubicYard: 998,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '6" azalea (1.5 qt)',
          containersPerCubicYard: 539,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '6" hanging basket (1.5 qt)',
          containersPerCubicYard: 539,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '6" standard (1.75 qt)',
          containersPerCubicYard: 462,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '6.5" azalea (1.8 qt)',
          containersPerCubicYard: 449,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '8" azalea (3 qt)',
          containersPerCubicYard: 269,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '8" hanging basket (3 qt)',
          containersPerCubicYard: 269,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '8" mum pan (1 gal)',
          containersPerCubicYard: 260,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '9" mum pan (1.25 gal)',
          containersPerCubicYard: 166,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '10" hanging basket (1.5 gal)',
          containersPerCubicYard: 150,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '12" color bowl (2 gal)',
          containersPerCubicYard: 112,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '12" hanging basket (2.25 gal)',
          containersPerCubicYard: 100,
          low: "",
          medium: "",
          high: "",
        },
      ],
      nurseryContainerRates: [
        {
          label: "1 quart",
          containersPerCubicYard: 850,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: "2 quart",
          containersPerCubicYard: 400,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: "1 gallon trade",
          containersPerCubicYard: 300,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: "1 gallon",
          containersPerCubicYard: 210,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: "2 gallon trade",
          containersPerCubicYard: 125,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: "2 gallon",
          containersPerCubicYard: 102,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: "3 gallon",
          containersPerCubicYard: 70,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: "5 gallon",
          containersPerCubicYard: 52,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: "7 gallon",
          containersPerCubicYard: 35,
          low: "",
          medium: "",
          high: "",
        },
      ],
      largeContainerRates: [
        {
          label: '10 gallon - 17" diam.',
          surfArea: 1.4,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '15 gallon - 17.5" diam.',
          surfArea: 1.5,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '20 gallon - 21" diam.',
          surfArea: 2.3,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '25 gallon - 22.5" diam.',
          surfArea: 2.8,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '30 gallon - 26.5" diam.',
          surfArea: 3.8,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '45 gallon - 30" diam.',
          surfArea: 4.8,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '65 gallon - 30" diam.',
          surfArea: 4.8,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '100 gallon - 36" diam.',
          surfArea: 7.1,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: '200 gallon - 48.5" diam. ',
          surfArea: 12.8,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: "24 inch box",
          surfArea: 4.0,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: "30 inch box",
          surfArea: 6.25,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: "36 inch box",
          surfArea: 9.0,
          low: "",
          medium: "",
          high: "",
        },
        {
          label: "48 inch box",
          surfArea: 16.0,
          low: "",
          medium: "",
          high: "",
        },
      ],
      landscapeBedContainerRates: [
        {
          label:
            "Other larger containers - use these rates multiplied by actual container surface area in sq. ft.",
          surfArea: 1.0,
          low: "",
          medium: "",
          high: "",
        },
      ],
    }

    this.updateApplicationEnv = this.updateApplicationEnv.bind(this)
    this.calculateRates = this.calculateRates.bind(this)
    this.toggleQuoteForm = this.toggleQuoteForm.bind(this)
  }
  updateApplicationEnv(e) {
    this.setState({
      applicationEnv: e.target.value,
    })
  }

  toggleQuoteForm() {
    this.setState({
      showQuoteForm: !this.state.showQuoteForm
    })
  }

  calculateRates(e) {
    // Prevent default form behavior
    //e.preventDefault();

    // Clone the current state and make calculations to the new object
    var obj = this.state

    // Make changes to the object
    // obj.longevity = e.target.value
    obj.longevity = this.props.data.wp.product.longevityRanges?.nodes[0]?.slug

    Object.keys(obj.incorporationRates.poundsPerCubicYard).map(level => {
      return (obj.incorporationRates.poundsPerCubicYard[level] = round(
        initialValuesPoundsNPerCubicYard[level][obj.longevity] /
          (obj.nValue / 100),
        1
      ))
    })

    Object.keys(obj.incorporationRates.kilogramsPerCubicMeter).map(level => {
      return (obj.incorporationRates.kilogramsPerCubicMeter[level] = round(
        initialValuesPoundsNPerCubicYard[level][obj.longevity] /
          (obj.nValue / 100) /
          2.2056 /
          0.7646,
        1
      ))
    })

    Object.keys(obj.incorporationRates.gramsPerLiter).map(level => {
      return (obj.incorporationRates.gramsPerLiter[level] = round(
        ((initialValuesPoundsNPerCubicYard[level][obj.longevity] /
          (obj.nValue / 100)) *
          453.5924) /
          764.6,
        1
      ))
    })

    Object.keys(obj.landscapeBedRates.poundsPerThousandSqFt).map(level => {
      return (obj.landscapeBedRates.poundsPerThousandSqFt[level] = round(
        50 /
          ((50 * (obj.nValue / 100)) /
            initialValuesPoundsNPerCubicYardLandscapeBed[level][obj.longevity]),
        1
      ))
    })

    obj.greenhouseContainerRates.map(item => {
      item.low = round(
        (obj.incorporationRates.poundsPerCubicYard.low * 454) /
          item.containersPerCubicYard,
        1
      )
      item.medium = round(
        (obj.incorporationRates.poundsPerCubicYard.medium * 454) /
          item.containersPerCubicYard,
        1
      )
      item.high = round(
        (obj.incorporationRates.poundsPerCubicYard.high * 454) /
          item.containersPerCubicYard,
        1
      )
      return item
    })

    obj.nurseryContainerRates.map(item => {
      item.low = round(
        (obj.incorporationRates.poundsPerCubicYard.low * 454) /
          item.containersPerCubicYard,
        1
      )
      item.medium = round(
        (obj.incorporationRates.poundsPerCubicYard.medium * 454) /
          item.containersPerCubicYard,
        1
      )
      item.high = round(
        (obj.incorporationRates.poundsPerCubicYard.high * 454) /
          item.containersPerCubicYard,
        1
      )
      return item
    })

    obj.landscapeBedContainerRates.map(item => {
      item.low = round(
        (((obj.incorporationRates.poundsPerCubicYard.low * 454) / 27) * 8) / 12,
        1
      )
      item.medium = round(
        (((obj.incorporationRates.poundsPerCubicYard.medium * 454) / 27) * 8) /
          12,
        1
      )
      item.high = round(
        (((obj.incorporationRates.poundsPerCubicYard.high * 454) / 27) * 8) /
          12,
        1
      )
      return item
    })

    obj.largeContainerRates.map(item => {
      item.low = round(
        ((((obj.incorporationRates.poundsPerCubicYard.low * 454) / 27) * 8) /
          12) *
          item.surfArea,
        1
      )
      item.medium = round(
        ((((obj.incorporationRates.poundsPerCubicYard.medium * 454) / 27) * 8) /
          12) *
          item.surfArea,
        1
      )
      item.high = round(
        ((((obj.incorporationRates.poundsPerCubicYard.high * 454) / 27) * 8) /
          12) *
          item.surfArea,
        1
      )
      return item
    })

    // Then set the state to the new object
    this.setState({...this.state, obj})
  }

  componentDidMount() {
    this.calculateRates()
  }
  render() {
    const { product } = this.props.data.wp

    const { location } = this.props

    const productUrl =
      this.props?.data?.site?.siteMetadata?.siteURL +
        this.props?.location?.pathname || null

    const longevityRanges = product.longevityRanges.nodes.map(
      range => range.slug
    )

    const formattedTitle = formatProductTitle(
      product.title,
      longevityRanges.join(" ", ", ")
    )

    return (
      <Layout
        pageTitle={product.title}
        slug={product.slug}
        location={this.props.location}
        noHeaderImage={true}
      >
        <SEO title={product.title} />

        <div className="container mt-5 pt-3 pt-md-5">
          <BackButton className="btn btn-link mb-2">Back</BackButton>

          <div className="row">
            <div className="col-12 col-md-6 mb-3">
              <Img
                fluid={
                  product.featuredImage.node.imageFile.childImageSharp.fluid
                }
                loading="eager"
              />
            </div>
            <div className="col-12 col-md-6">
              <header className="product-header">
                <div className="row align-items-end">
                  <div className="col">
                    <h1 className="product-title h2">{formattedTitle}</h1>
                  </div>
                  <div className="col-auto text-center">
                    <p className="small mb-1 text-muted">Share</p>
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a
                          href={`sms:%20&body=Check out ${encodeURI(
                            product.title
                          )}: ${encodeURI(productUrl)}`}
                          className="d-block pl-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="currentColor"
                            viewBox="0 0 30 30"
                          >
                            <path d="M7.4 25h.3l.3.3c2.1 1.4 4.5 2.2 7 2.2 6.9 0 12.5-5.6 12.5-12.5S22 2.4 15.1 2.4 2.5 8 2.5 14.9c0 2.5.8 5 2.2 7.1l.3.4v.3l-1.2 3.5L7.4 25zM.1 14.9c0-4 1.6-7.7 4.4-10.6C7.3 1.6 11.1 0 15.1 0s7.7 1.6 10.6 4.4c2.8 2.8 4.4 6.6 4.4 10.6s-1.6 7.7-4.4 10.6c-2.8 2.8-6.6 4.4-10.6 4.4-2.8 0-5.5-.8-7.9-2.3L0 30l2.4-7.2C.9 20.5.1 17.8.1 14.9z" />
                          </svg>
                          <span className="sr-only">Message</span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={`mailto:?subject=${encodeURI(
                            product.title
                          )}&body=Check out ${encodeURI(
                            product.title
                          )}: ${encodeURI(productUrl)}`}
                          className="d-block pl-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="currentColor"
                            viewBox="0 0 30 30"
                          >
                            <path d="M27.5 6.5h-25v2.8L15 15.5l12.5-6.4V6.5zm0 5.4L15 18.3 2.5 12.1v11.4h24.9l.1-11.6zm2.5-8V26H0V3.9h30z" />
                          </svg>
                          <span className="sr-only">Email</span>
                        </a>
                      </li>

                      {product.productFields.productInformationPdf?.mediaItemUrl && (
                      <li className="list-inline-item">
                        <a
                          href={product.productFields.productInformationPdf.mediaItemUrl}
                          target="_blank"
                          className="d-block pl-2"
                          title="Print"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                          </svg>
                          <span className="sr-only">Download/Print Product Information Sheet</span>
                        </a>
                      </li>
                    )}
                  
                    </ul>
                  </div>
                </div>
              </header>

              {/* Main Content */}
              {product.content && (
                <div
                  className="product-description"
                  dangerouslySetInnerHTML={{ __html: product.content }}
                />
              )}

              {product.productFields.productInformationPdf?.mediaItemUrl && (
                <div className="col-12">
                  <a
                    href={product.productFields.productInformationPdf.mediaItemUrl}
                    target="_blank"
                    className="text-primary font-weight-bold my-3 d-inline-block"
                  >
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>&nbsp;
                      Download Product Information Sheet</span>
                  </a>
                </div>
              )}
              

              <button
                onClick={this.toggleQuoteForm}
                type="button"
                aria-label="Toggle quote form"
                className="btn btn-icl btn-block btn-lg btn-skew my-3 d-none d-md-block"
              >
                <span>Request a Quote</span>
              </button>
            </div>
          </div>
          <div className="product-data" style={{ marginTop: 20 }}>
            <h2 class="font-weight-normal text-icl">Find recommended rates</h2>
            <SelectField
              label="Select application/environment"
              name="productApplicationEnv"
              value={this.state.applicationEnv}
              onSelectChange={this.updateApplicationEnv}
              labelAboveField
            >
              <option value="incorporation">Incorporation</option>
              <option value="landscape-bed">Landscape bed</option>
              <option value="greenhouse">
                Greenhouse container (topdress)
              </option>
              <option value="nursery">Nursery container (topdress)</option>
              <option value="large-topdress">Large container (topdress)</option>
              <option value="large-landscape">
                Large container (landscape bed)
              </option>
            </SelectField>
            <div className="table-responsive-wrapper">
              {this.state.applicationEnv === "incorporation" && (
                <>
                  <h2 class="font-weight-normal text-icl">Recommended incorporation rates</h2>
                  <div className="table-responsive">
                    <table className="table table__no-overflow">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Low</th>
                          <th>Medium</th>
                          <th>High</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(this.state.incorporationRates).map(
                          (item, i) => {
                            let uncamelcased = item.replace(/([A-Z])/g, " $1")
                            let th =
                              uncamelcased.charAt(0).toUpperCase() +
                              uncamelcased.slice(1).toLowerCase()
                            return (
                              <tr key={item}>
                                <td>{th}</td>
                                {Object.keys(
                                  this.state.incorporationRates[item]
                                ).map(td => (
                                  <td key={td}>
                                    {this.state.incorporationRates[item][td]}
                                  </td>
                                ))}
                              </tr>
                            )
                          }
                        )}
                      </tbody>
                    </table>
                    <div className="table-fade"></div>
                  </div>
                </>
              )}
              {this.state.applicationEnv === "landscape-bed" && (
                <>
                  <h2 class="font-weight-normal text-icl">Recommended landscape bed rates</h2>
                  <div className="table-responsive">
                    <table className="table table__no-overflow">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Clay</th>
                          <th>Loam</th>
                          <th>Sand</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(this.state.landscapeBedRates).map(
                          (item, i) => {
                            return (
                              <tr key={i}>
                                <td>Pounds of Osmocote per 1,000 sq. ft.</td>
                                {Object.keys(
                                  this.state.landscapeBedRates[item]
                                ).map(td => (
                                  <td key={td}>
                                    {this.state.landscapeBedRates[item][td]}
                                  </td>
                                ))}
                              </tr>
                            )
                          }
                        )}
                      </tbody>
                    </table>
                    <div className="table-fade"></div>
                  </div>
                </>
              )}
              {this.state.applicationEnv === "greenhouse" && (
                <>
                  <h2 class="font-weight-normal text-icl">Recommended greenhouse container (topdress) rates</h2>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Greenhouse</th>
                          <th>Containers per cubic yard (approx.)</th>
                          <th>Low rate (grams)</th>
                          <th>Medium rate (grams)</th>
                          <th>High rate (grams)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.greenhouseContainerRates.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.label}</td>
                              <td>{item.containersPerCubicYard}</td>
                              <td>{item.low}</td>
                              <td>{item.medium}</td>
                              <td>{item.high}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <div className="table-fade"></div>
                  </div>
                </>
              )}

              {this.state.applicationEnv === "nursery" && (
                <>
                  <h2 class="font-weight-normal text-icl">Recommended nursery container (topdress) rates</h2>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Nursery</th>
                          <th>Containers per cubic yard (approx.)</th>
                          <th>Low rate (grams)</th>
                          <th>Medium rate (grams)</th>
                          <th>High rate (grams)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.nurseryContainerRates.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.label}</td>
                              <td>{item.containersPerCubicYard}</td>
                              <td>{item.low}</td>
                              <td>{item.medium}</td>
                              <td>{item.high}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <div className="table-fade"></div>
                  </div>
                </>
              )}

              {this.state.applicationEnv === "large-topdress" && (
                <>
                  <h2 class="font-weight-normal text-icl">Recommended large container (topdress) rates</h2>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Large containers</th>
                          <th>Surf. area (sq. ft.)</th>
                          <th>Low rate (grams)</th>
                          <th>Medium rate (grams)</th>
                          <th>High rate (grams)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.largeContainerRates.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.label}</td>
                              <td>{item.surfArea}</td>
                              <td>{item.low}</td>
                              <td>{item.medium}</td>
                              <td>{item.high}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <div className="table-fade"></div>
                  </div>
                </>
              )}

              {this.state.applicationEnv === "large-landscape" && (
                <>
                  <h2 class="font-weight-normal text-icl">Recommended large container (landscape bed) rates</h2>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            Large container landscape bed (rates per square
                            foot)
                          </th>
                          <th>Surf. area (sq. ft.)</th>
                          <th>Low rate (grams)</th>
                          <th>Medium rate (grams)</th>
                          <th>High rate (grams)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.landscapeBedContainerRates.map(
                          (item, i) => {
                            return (
                              <tr key={i}>
                                <td>{item.label}</td>
                                <td>{item.surfArea}</td>
                                <td>{item.low}</td>
                                <td>{item.medium}</td>
                                <td>{item.high}</td>
                              </tr>
                            )
                          }
                        )}
                      </tbody>
                    </table>
                    <div className="table-fade"></div>
                  </div>
                </>
              )}
            </div>
          </div>
          <button
            onClick={this.toggleQuoteForm}
            type="button"
            aria-label="Toggle quote form"
            className="btn btn-icl btn-block btn-skew btn-lg mb-3 d-md-none"
          >
            <span>Request a Quote</span>
          </button>
          <Modal
            show={this.state.showQuoteForm}
            onHide={this.toggleQuoteForm}
            size="lg"
            aria-labelledby="quote-form-title"
            className="quote-form-overlay"
          >
            <Modal.Header closeButton>
              <div className="modal-title text-icl display-4" id="quote-form-title">
                Request a Quote
              </div>
            </Modal.Header>
            <div className="modal-custom-content p-3">
              <QuoteForm productsToShow={location?.state?.productsToShow ? location.state.productsToShow : "all"} activeProduct={product} />
            </div>
          </Modal>   
        </div>
      </Layout>
    )
  }
}

export default ProductTemplate

export const pageQuery = graphql`
  query($id: ID!) {
    wp {
      product(id: $id, idType: DATABASE_ID) {
        databaseId
        title
        content
        slug
        featuredImage {
          node {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        productFields {
          nValue
          productInformationPdf {
            mediaItemUrl
          }
        }
        longevityRanges {
          nodes {
            databaseId
            name
            slug
          }
        }
      }
    }

    site {
      id
      siteMetadata {
        title
        siteURL
      }
    }
  }
`
